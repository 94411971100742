import Vue from 'vue';
import Md5 from 'crypto-js/md5';
import Hex from 'crypto-js/enc-hex';
// import Utf8 from 'crypto-js/enc-utf8';


const sign_key = "tttttttttt";

export const getSign = (params,noNeedSignParams = [])=>{
  if(!params) return '';
  var key = sign_key;
  var allKeys = [];
  // console.log("getSign params:",params);

  for(var k in params){
    if(!noNeedSignParams || noNeedSignParams.indexOf(k) == -1){
      var value = params[k];
      allKeys.push(k+"="+value);
    }
  }
  allKeys.sort();
  var pairsStr = allKeys.join(';');
  var sign = key+"&"+pairsStr+'&'+key;
  // sign = 'jyl_sign_key&mobile=15821613021;password=123456;timestamp=1606809847&jyl_sign_key';
  Vue.$log.debug("before sign str: ",sign);
  return Md5(sign).toString(Hex).toUpperCase();
}

export const formatTime = function(date,fmt)   
{ //author: meizz   
  var o = {   
    "M+" : date.getMonth()+1,                 //月份   
    "d+" : date.getDate(),                    //日   
    "h+" : date.getHours(),                   //小时   
    "m+" : date.getMinutes(),                 //分   
    "s+" : date.getSeconds(),                 //秒   
    "q+" : Math.floor((date.getMonth()+3)/3), //季度   
    "S"  : date.getMilliseconds()             //毫秒   
  };   
  if(/(y+)/.test(fmt))   
    fmt=fmt.replace(RegExp.$1, (date.getFullYear()+"").substr(4 - RegExp.$1.length));   
  for(var k in o)   
    if(new RegExp("("+ k +")").test(fmt))   
  fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));   
  return fmt;   
}  

export const getDateFromStartToEnd = function(startTime,endTime){
  // console.log('start------end',startTime,endTime);
  if(startTime.getTime() == endTime.getTime()){
    return [formatTime(startTime,'yyyy-MM-dd')];
  }else{
    var result = [];
    result.push(formatTime(startTime,'yyyy-MM-dd'));
    var middleTime =  new Date();
    middleTime.setTime(startTime.getTime() + 3600 * 1000 * 24);
    // console.log('middletime: ', middleTime);
    while(middleTime.getTime() < endTime.getTime()){
      result.push(formatTime(middleTime,'yyyy-MM-dd'));
      startTime = middleTime;
      middleTime.setTime(startTime.getTime() + 3600 * 1000 * 24);
    }
    result.push(formatTime(endTime,'yyyy-MM-dd'));
    return result;
  }
}