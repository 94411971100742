<template>
  <div class="user-statics-container">
    <div class="filter-container">
      <div class="brand-filter">
        <span>商家:</span>
        <Dropdown style="margin-left: 6px" @on-click="onBrandItemClick">
          <Button type="primary">
            <span>{{ brandName }}</span>
            <Icon type="ios-arrow-down"></Icon>
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="-1" :selected="brandType == -1"
              >全部</DropdownItem
            >
            <DropdownItem
              :name="item.id"
              :selected="brandType == item.id"
              v-for="(item, index) in brandList"
              :key="index"
              >{{ item.name }}</DropdownItem
            >
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
    <div class="statis-info">
      <div class="total-statis">
        <div class="statis-item">
          总用户数<span>{{ statisData.total_user_count }}</span>
        </div>
        <div class="statis-item">
          总激活数<span>{{ statisData.total_active_count }}</span>
        </div>
        <div class="statis-item">
          总支付次数<span>{{ statisData.total_pay_count }}</span>
        </div>
        <div class="statis-item">
          总户型数<span>{{ statisData.total_house_count }}</span>
        </div>
        <div class="statis-item">
          总cad下载数<span>{{ statisData.total_cad_count }}</span>
        </div>
      </div>

      <div class="today-statis">
        <div class="statis-item">
          今日用户数<span>{{ statisData.today_user_count }}</span>
        </div>
        <div class="statis-item">
          今日激活数<span>{{ statisData.today_active_count }}</span>
        </div>
        <div class="statis-item">
          今日支付次数<span>{{ statisData.today_pay_count }}</span>
        </div>
        <div class="statis-item">
          今日户型数<span>{{ statisData.today_house_count }}</span>
        </div>
        <div class="statis-item">
          今日cad下载数<span>{{ statisData.today_cad_count }}</span>
        </div>
      </div>
    </div>

    <div class="chart-container">
      <div class="month-filter">
        <span>统计时间范围: </span>
        <DatePicker
          type="daterange"
          placement="bottom-end"
          placeholder="请选择日期范围,默认最近一个月"
          style="width: 200px"
          @on-change="handleDateChange"
          :options="dateOptions"
        ></DatePicker>
      </div>

      <div id="chart"></div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import echarts from "@/echart.js";
import { formatTime,getDateFromStartToEnd } from "@/util/common";

export default {
  data() {
    return {
      chart: null,
      brandType: -1,
      brandList: [],
      statisData: {},
      startTime: null,
      endTime: null,
      dateOptions: {
        shortcuts: [
          {
            text: "今日",
            value() {
              const start = new Date();
              return [start, start];
            },
          },
          {
            text: "昨日",
            value() {
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              return [start, start];
            },
          },
          {
            text: "最近7天",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            },
          },
          {
            text: "最近1月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            },
          },
        ],
      },
    };
  },
  computed: {
    brandName() {
      if (this.brandType == -1) return "全部";
      return this.brandList.filter((item) => {
        return item.id == this.brandType;
      })[0].name;
    },
  },
  methods: {
    onBrandItemClick(name) {
      this.$log.debug("brand type: ", name);
      if (name != this.brandType) {
        this.brandType = name;
        this.loadData();
      }
    },
    loadData() {
      let btype = this.brandType;
      if (!this.startTime || !this.endTime) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        this.startTime = formatTime(start, "yyyy-MM-dd");
        this.endTime = formatTime(end, "yyyy-MM-dd");
        this.$log.debug(this.startTime, this.endTime);
      }
      this.$log.debug("time is: ", this.startTime, this.endTime);
      var sDate = new Date(this.startTime);
      sDate.setHours(0, 0, 0);
      var eDate = new Date(this.endTime);
      eDate.setHours(23, 59, 59);
      this.$log.debug(sDate);
      this.$log.debug(eDate);
      var stime = sDate.getTime() / 1000;
      var etime = eDate.getTime() / 1000;
      api
        .statisticsInfo(btype, stime, etime)
        .then((res) => {
          this.$log.debug(res);
          if (res.data) {
            this.statisData = res.data;
            this.configChart();
          } else {
            this.userData = {};
          }
        })
        .catch((err) => {
          this.$log.debug(err);
        });
    },
    loadMerchants() {
      api
        .merchantList()
        .then((res) => {
          this.brandList = res.merchants;
          this.loadData();
        })
        .catch((err) => {
          this.$Message.error("拉取数据失败: " + err.message);
        });
    },
    handleDateChange(val) {
      this.$log.debug(val);
      this.startTime = val[0];
      this.endTime = val[1];
      this.loadData();
    },
    configChart() {
      this.$log.debug("configchart");
      let data = this.statisData.day_data || {};

      var xAxisData = [];
      var series0 = [];
      var series1 = [];
      var series2 = [];
      var series3 = [];
      var series4 = [];
      var allData = getDateFromStartToEnd(new Date(this.startTime),new Date(this.endTime));
      this.$log.debug(allData);
      allData.forEach((key) => {
        xAxisData.push(key);
        if (data[key]) {
          series0.push(data[key].user_count);
          series1.push(data[key].active_count);
          series2.push(data[key].pay_count);
          series3.push(data[key].house_count);
          series4.push(data[key].cad_count);
        }else{
          series0.push(0);
          series1.push(0);
          series2.push(0);
          series3.push(0);
          series4.push(0);
        }
      });
      this.chart.setOption({
        xAxis: {
          data: xAxisData,
        },
        series: [
          {
            name: "注册用户数",
            data: series0,
          },
          {
            name: "激活用户数",
            data: series1,
          },
          {
            name: "支付次数",
            data: series2,
          },
          {
            name: "户型数",
            data: series3,
          },
          {
            name: "cad下载数量",
            data: series4,
          },
        ],
      });
    },
    initChart() {
      this.chart = echarts.init(document.getElementById("chart"));
      // 显示标题，图例和空的坐标轴
      this.chart.setOption({
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [
            "注册用户数",
            "激活用户数",
            "支付次数",
            "户型数",
            "cad下载数量",
          ],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "注册用户数",
            type: "line",
            data: [],
            smooth: true,
          },
          {
            name: "激活用户数",
            type: "line",
            data: [],
            smooth: true,
          },
          {
            name: "支付次数",
            type: "line",
            data: [],
            smooth: true,
          },
          {
            name: "户型数",
            type: "line",
            data: [],
            smooth: true,
          },
          {
            name: "cad下载数量",
            type: "line",
            data: [],
            smooth: true,
          },
        ],
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
      });
    },
  },
  mounted() {
    this.initChart();
    this.loadMerchants();
  },
  destroyed() {},
};
</script>

<style lang="less" scoped>
.user-statics-container {
  width: 100%;
  padding: 12px;

  .filter-container {
    margin-bottom: 12px;
    padding: 12px;
    border: 1px dashed green;
    display: flex;
  }

  .statis-info {
    margin-top: 12px;
    padding: 12px;
    border: 1px dashed green;

    .total-statis,
    .today-statis {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      .statis-item {
        span {
          margin-left: 6px;
          color: green;
          font-size: 18px;
        }
      }
    }
  }

  .chart-container {
    margin-top: 12px;
    padding: 12px;
    border: 1px dashed green;
    display: flex;
    flex-direction: column;

    #chart {
      width: 100%;
      height: 440px;
      margin-top: 12px;
    }
  }
}
</style>